.deyanemo {
    position: relative;
    display: block;
    background: #1e5799 !important;
    background: -moz-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%) !important;
    background: -webkit-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%) !important;
    background: linear-gradient(to right, #1e5799 0%, #01b1e4 0%, #1764a5 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#1764a5', GradientType=1);
    float: right;
    clear: both;
    color: #fff;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 30px !important;
}