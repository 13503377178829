* {
  font-family: "Raleway" !important;

}
.step {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    padding: 100px;
}
.select_tag {
    min-width: 100px;
    height: 40px;
    background-color: blueviolet;
    color: white;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
}
.selected {
    position: relative;
    background-color: cornflowerblue;
    transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
}

.left {
    position: absolute;
    top: 13px;
    left: 10px;
}
.skill_level_container {
    display: inline-block;
    list-style-type: none;
    transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
}
.skill_level_item {
    padding: 10px;
    color: #fff;
    background-color: #333;
    cursor: pointer;
    opacity: 1;
    transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
}
.shadow-drop-2-center {
	-webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
ul {
  padding: 30px;
}
ul li {
  padding: 1px;
  list-style-type: disc;
}

.gradiant_git {
  border: none;
  outline: none;
  cursor: pointer;
  background: #1e5799;
  background: -moz-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
  background: -webkit-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
  background: linear-gradient(to right, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#1764a5', GradientType=1);
  color: #fff !important;
  font-size: .8rem;
  padding: 1.1rem;
}

.is-100 {
  width: 80%;
}

.gradiant_btn {
  border: none;
  outline: none;
  cursor: pointer;
  background: #1e5799;
  background: -moz-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
  background: -webkit-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
  background: linear-gradient(to right, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#1764a5', GradientType=1);
  transition: all ease-in-out;
  text-decoration: none;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-size: 0.7em;
  font-weight: bold;
  text-transform: uppercase;
  max-width: 300px;
  border-radius: 4px;
  padding-left: 10px;
  /* width: 260px; */
  padding-right: 10px;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
}

.button {
  color: #fff !important;
}


.slacStep {
  padding-top: 4px;
  padding-left: 26px;
  color: #767676;
}

.slacStep li  {
  color: #767676;

  list-style-type: disc;
}

.gray_color {
  color: #767676 !important;
}

.dark_color {
  color: #333 !important;
}


.p10 {
  padding: 10px;
  font-size: 1.2em;
}
.git10 {
  font-size: 1.2em;
}

.p2 {
  padding: 0 0 20px;
  font-size: 1.2em;

}

 @-webkit-keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  @keyframes shadow-drop-2-center {
    0% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-transform: translateZ(50px);
              transform: translateZ(50px);
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  .avatar {
    width: 60px !important;
    height: 60px !important;
  }
  
.su_title {
  padding: 15px 0;
  font-weight: 400;
  font-size: 1.4rem;
  color: #000;
}
.countdown {
  width: 100px;
  color: #767676;
  /* margin-top: 15px; */
  font-style: italic;
}
.no-point {
  list-style-type: none !important;
}

.containers {
  width: 100%;
}
.behave_as_p {
  display: block;
}

.notesul {
  padding-top: 4px;
  padding-left: 26px;
  color: #767676;
  list-style: none;
}

.notes {
  font-size: .9rem;
  padding: 10px;
  color: #202020;
  position: relative;
  list-style: none;
}
.notes::before {
  font-family: "fontAwesome";
  left: -30px;
  top: 40%;
  position: absolute;
  content: "\f059";
  width: 100px;
  height: 100px;
  color: #000;
}

.map {
  width: 600px !important;
  height: 400px !important;
  overflow: hidden;
}


@media screen and (max-width:450px) {
  .containers {
    width: 300px;
  }
  .map {
    width: 300px !important;
    height: 400px !important;
    overflow: hidden;
  }
}