.bold {
    font-weight: bold;
    min-width: 200px;
}

.centercontent {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    width: 50%;
    padding: 10px;
    margin-top: -120px;
}

.marker-style {
    background-color: #48bae4 !important;
    border-color: #48bae4 !important;
}

.timeline-item::before {
    background-color: #48bae4 !important;
}

.box {
    font-family: "Lato", sans-serif;
    display: block;
    position: relative;
    min-width: 90vh;
    color: #5f5f5f;
    font-size: 1rem;
    background-color: #fff;
    padding: 20px;
    margin-left: -20px;
    margin-top: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

}

.link {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8em;
    color: #01B2E4;
}

.link_details {
    margin-left: 25px;
}

.timestamp {
    font-size: .5em;
    color: grey;
    float: right;
    clear: both;
}

.not_completed {
    background-color: #fff !important;
}

.align_left {
    float: right;
    clear: both;

}

.grdiant_btn {
    border: none;
    outline: none;
    cursor: pointer;
    background: #1e5799;
    background: -moz-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
    background: -webkit-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
    background: linear-gradient(to right, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#1764a5', GradientType=1);
    transition: all ease-in-out;
    width: 200px;
    text-decoration: none;
    color: #fff;
    text-align: center;
    padding: 2px;
    font-size: 0.7em;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 300px;
    border-radius: 4px;
}

@media screen and (max-width:480px) {
    .centercontent {
        position: relative;
        display: flex;
        align-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        padding: 10px;
        margin-top: -120px;
    }
}