body, html {
  margin: 0;
	padding: 0;
	font-family: "Lato", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f7f7f7 !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.container,
.container-sm {
	width: 100%;
	margin: 0 auto;
	padding-left: 16px;
	padding-right: 16px
}

@media (min-width: 481px) {
	.container,
	.container-sm {
		padding-left: 24px;
		padding-right: 24px
	}
}

.container {
	max-width: 1128px
}

.container-sm {
	max-width: 848px
}

.container .container-sm {
	max-width: 800px;
	padding-left: 0;
	padding-right: 0
}
html {
	line-height: 1.15;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%
}

body {
	margin: 0
}

article,
aside,
footer,
header,
nav,
section {
	display: block
}

h1 {
	font-size: 2em;
	margin: 0.67em 0
}

figcaption,
figure,
main {
	display: block
}

figure {
	margin: 1em 40px
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible
}

pre {
	font-family: monospace, monospace;
	font-size: 1em
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects
}

abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted
}

b,
strong {
	font-weight: inherit
}

b,
strong {
	font-weight: bolder
}

code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em
}

dfn {
	font-style: italic
}

mark {
	background-color: #ff0;
	color: #000
}

small {
	font-size: 80%
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

sub {
	bottom: -0.25em
}

sup {
	top: -0.5em
}

audio,
video {
	display: inline-block
}

audio:not([controls]) {
	display: none;
	height: 0
}

img {
	border-style: none
}

svg:not(:root) {
	overflow: hidden
}

button,
input,
optgroup,
select,
textarea {
	font-family: sans-serif;
	font-size: 100%;
	line-height: 1.15;
	margin: 0
}

button,
input {
	overflow: visible
}

button,
select {
	text-transform: none
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText
}

fieldset {
	padding: 0.35em 0.75em 0.625em
}

legend {
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal
}

progress {
	display: inline-block;
	vertical-align: baseline
}

textarea {
	overflow: auto
}

[type="checkbox"],
[type="radio"] {
	box-sizing: border-box;
	padding: 0
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto
}

[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit
}

details,
menu {
	display: block
}

summary {
	display: list-item
}

canvas {
	display: inline-block
}

template {
	display: none
}

[hidden] {
	display: none
}

html {
	box-sizing: border-box
}

*,
*:before,
*:after {
	box-sizing: inherit
}

body {
	background: #fff;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased
}

hr {
	border: 0;
	display: block;
	height: 1px;
	background: #E3E7EB;
	background: linear-gradient(to right, rgba(227, 231, 235, 0.1) 0, rgba(227, 231, 235, 0.6) 50%, rgba(227, 231, 235, 0.1) 100%);
	margin-top: 24px;
	margin-bottom: 24px
}

ul,
ol {
	margin-top: 0;
	margin-bottom: 24px;
	/* padding-left: 24px */
}

ul {
	list-style: disc
}

ol {
	list-style: decimal
}

li>ul,
li>ol {
	margin-bottom: 0
}

dl {
	margin-top: 0;
	margin-bottom: 24px
}

dt {
	font-weight: 700
}

dd {
	margin-left: 24px;
	margin-bottom: 24px
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle
}

figure {
	margin: 24px 0
}

figcaption {
	font-size: 16px;
	line-height: 24px;
	padding: 8px 0
}

img,
svg {
	display: block
}

table {
	border-collapse: collapse;
	margin-bottom: 24px;
	width: 100%
}

tr {
	border-bottom: 1px solid #E3E7EB
}

th {
	text-align: left
}

th,
td {
	padding: 10px 16px
}

th:first-child,
td:first-child {
	padding-left: 0
}

th:last-child,
td:last-child {
	padding-right: 0
}

html {
	font-size: 18px;
	line-height: 27px
}

@media (min-width: 641px) {
	html {
		font-size: 20px;
		line-height: 30px;
		letter-spacing: -0.1px
	}
}

body {
	color: #5B6F82;
	font-size: 1rem
}

body,
button,
input,
select,
textarea {
	font-family: "Lato", sans-serif
}

a {
	color: inherit;
	text-decoration: underline
}

a:hover,
a:active {
	outline: 0;
	text-decoration: none
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	clear: both;
	color: #202B36;
	font-family: "Montserrat", sans-serif;
	font-weight: 700
}

h1,
.h1 {
	font-size: 36px;
	line-height: 46px;
	letter-spacing: -0.2px
}

@media (min-width: 641px) {
	h1,
	.h1 {
		font-size: 42px;
		line-height: 52px;
		letter-spacing: -0.1px
	}
}

h2,
.h2 {
	font-size: 30px;
	line-height: 40px;
	letter-spacing: -0.1px
}

@media (min-width: 641px) {
	h2,
	.h2 {
		font-size: 36px;
		line-height: 46px;
		letter-spacing: -0.2px
	}
}

h3,
.h3,
blockquote {
	font-size: 20px;
	line-height: 30px;
	letter-spacing: -0.1px
}

@media (min-width: 641px) {
	h3,
	.h3,
	blockquote {
		font-size: 30px;
		line-height: 40px;
		letter-spacing: -0.1px
	}
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.1px
}

@media (min-width: 641px) {
	h4,
	h5,
	h6,
	.h4,
	.h5,
	.h6 {
		font-size: 20px;
		line-height: 30px;
		letter-spacing: -0.1px
	}
}

@media (max-width: 640px) {
	.h1-mobile {
		font-size: 36px;
		line-height: 46px;
		letter-spacing: -0.2px
	}
	.h2-mobile {
		font-size: 30px;
		line-height: 40px;
		letter-spacing: -0.1px
	}
	.h3-mobile {
		font-size: 20px;
		line-height: 30px;
		letter-spacing: -0.1px
	}
	.h4-mobile,
	.h5-mobile,
	.h6-mobile {
		font-size: 18px;
		line-height: 27px;
		letter-spacing: -0.1px
	}
}

.text-light {
	color: rgba(255, 255, 255, 0.8)
}

.text-light a {
	color: rgba(255, 255, 255, 0.8)
}

.text-light h1,
.text-light h2,
.text-light h3,
.text-light h4,
.text-light h5,
.text-light h6,
.text-light .h1,
.text-light .h2,
.text-light .h3,
.text-light .h4,
.text-light .h5,
.text-light .h6 {
	color: #fff
}

.text-sm {
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.1px
}

.text-xs {
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.1px
}

h1,
h2,
.h1,
.h2 {
	margin-top: 48px;
	margin-bottom: 16px
}

h3,
.h3 {
	margin-top: 36px;
	margin-bottom: 12px
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
	margin-top: 24px;
	margin-bottom: 4px
}

p {
	margin-top: 0;
	margin-bottom: 24px
}

dfn,
cite,
em,
i {
	font-style: italic
}

blockquote {
	color: #92A2B1;
	font-style: italic;
	margin-top: 24px;
	margin-bottom: 24px;
	margin-left: 24px
}

blockquote::before {
	content: "\201C"
}

blockquote::after {
	content: "\201D"
}

blockquote p {
	display: inline
}

address {
	color: #5B6F82;
	border-width: 1px 0;
	border-style: solid;
	border-color: #E3E7EB;
	padding: 24px 0;
	margin: 0 0 24px
}

pre,
pre h1,
pre h2,
pre h3,
pre h4,
pre h5,
pre h6,
pre .h1,
pre .h2,
pre .h3,
pre .h4,
pre .h5,
pre .h6 {
	font-family: "Courier 10 Pitch", Courier, monospace
}

pre,
code,
kbd,
tt,
var {
	background: #F6F8FA
}

pre {
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 24px;
	margin-top: 24px;
	margin-bottom: 24px
}

code,
kbd,
tt,
var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
	font-size: 16px;
	padding: 2px 4px
}

abbr,
acronym {
	cursor: help
}

mark,
ins {
	text-decoration: none
}

small {
	font-size: 18px;
	line-height: 27px;
	letter-spacing: -0.1px
}

b,
strong {
	font-weight: 700
}

button,
input,
select,
textarea,
label {
	font-size: 18px;
	line-height: 27px
}

.container,
.container-sm {
	width: 100%;
	margin: 0 auto;
	padding-left: 16px;
	padding-right: 16px
}

@media (min-width: 481px) {
	.container,
	.container-sm {
		padding-left: 24px;
		padding-right: 24px
	}
}

.container {
	max-width: 1128px
}

.container-sm {
	max-width: 848px
}

.container .container-sm {
	max-width: 800px;
	padding-left: 0;
	padding-right: 0
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	word-wrap: normal !important
}

.screen-reader-text:focus {
	border-radius: 2px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	display: block;
	font-size: 13px;
	letter-spacing: 0px;
	font-weight: 700;
	line-height: 16px;
	text-transform: uppercase;
	text-decoration: none;
	background-color: #fff;
	color: #00A2B8 !important;
	border: none;
	height: auto;
	left: 8px;
	padding: 16px 32px;
	top: 8px;
	width: auto;
	z-index: 100000
}

.list-reset {
	list-style: none;
	padding: 0
}

.text-left {
	text-align: left
}

.text-center {
	text-align: center
}

.text-right {
	text-align: right
}

.text-primary {
	color: #00b5e7
}

.text-secondary {
	color: #00b5e7
}

.has-top-divider {
	position: relative
}

.has-top-divider::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: block;
	height: 1px;
	background: #E3E7EB;
	background: linear-gradient(to right, rgba(227, 231, 235, 0.1) 0, rgba(227, 231, 235, 0.6) 50%, rgba(227, 231, 235, 0.1) 100%)
}

.has-bottom-divider {
	position: relative
}

.has-bottom-divider::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: block;
	height: 1px;
	background: #E3E7EB;
	background: linear-gradient(to right, rgba(227, 231, 235, 0.1) 0, rgba(227, 231, 235, 0.6) 50%, rgba(227, 231, 235, 0.1) 100%)
}

.m-0 {
	margin: 0
}

.mt-0 {
	margin-top: 0
}

.mr-0 {
	margin-right: 0
}

.mb-0 {
	margin-bottom: 0
}

.ml-0 {
	margin-left: 0
}

.m-8 {
	margin: 8px
}

.mt-8 {
	margin-top: 8px
}

.mr-8 {
	margin-right: 8px
}

.mb-8 {
	margin-bottom: 8px
}

.ml-8 {
	margin-left: 8px
}

.m-16 {
	margin: 16px
}

.mt-16 {
	margin-top: 16px
}

.mr-16 {
	margin-right: 16px
}

.mb-16 {
	margin-bottom: 16px
}

.ml-16 {
	margin-left: 16px
}

.m-24 {
	margin: 24px
}

.mt-24 {
	margin-top: 24px
}

.mr-24 {
	margin-right: 24px
}

.mb-24 {
	margin-bottom: 24px
}

.ml-24 {
	margin-left: 24px
}

.m-32 {
	margin: 32px
}

.mt-32 {
	margin-top: 32px
}

.mr-32 {
	margin-right: 32px
}

.mb-32 {
	margin-bottom: 32px
}

.ml-32 {
	margin-left: 32px
}

.m-40 {
	margin: 40px
}

.mt-40 {
	margin-top: 40px
}

.mr-40 {
	margin-right: 40px
}

.mb-40 {
	margin-bottom: 40px
}

.ml-40 {
	margin-left: 40px
}

.m-48 {
	margin: 48px
}

.mt-48 {
	margin-top: 48px
}

.mr-48 {
	margin-right: 48px
}

.mb-48 {
	margin-bottom: 48px
}

.ml-48 {
	margin-left: 48px
}

.m-56 {
	margin: 56px
}

.mt-56 {
	margin-top: 56px
}

.mr-56 {
	margin-right: 56px
}

.mb-56 {
	margin-bottom: 56px
}

.ml-56 {
	margin-left: 56px
}

.m-64 {
	margin: 64px
}

.mt-64 {
	margin-top: 64px
}

.mr-64 {
	margin-right: 64px
}

.mb-64 {
	margin-bottom: 64px
}

.ml-64 {
	margin-left: 64px
}

.p-0 {
	padding: 0
}

.pt-0 {
	padding-top: 0
}

.pr-0 {
	padding-right: 0
}

.pb-0 {
	padding-bottom: 0
}

.pl-0 {
	padding-left: 0
}

.p-8 {
	padding: 8px
}

.pt-8 {
	padding-top: 8px
}

.pr-8 {
	padding-right: 8px
}

.pb-8 {
	padding-bottom: 8px
}

.pl-8 {
	padding-left: 8px
}

.p-16 {
	padding: 16px
}

.pt-16 {
	padding-top: 16px
}

.pr-16 {
	padding-right: 16px
}

.pb-16 {
	padding-bottom: 16px
}

.pl-16 {
	padding-left: 16px
}

.p-24 {
	padding: 24px
}

.pt-24 {
	padding-top: 24px
}

.pr-24 {
	padding-right: 24px
}

.pb-24 {
	padding-bottom: 24px
}

.pl-24 {
	padding-left: 24px
}

.p-32 {
	padding: 32px
}

.pt-32 {
	padding-top: 32px
}

.pr-32 {
	padding-right: 32px
}

.pb-32 {
	padding-bottom: 32px
}

.pl-32 {
	padding-left: 32px
}

.p-40 {
	padding: 40px
}

.pt-40 {
	padding-top: 40px
}

.pr-40 {
	padding-right: 40px
}

.pb-40 {
	padding-bottom: 40px
}

.pl-40 {
	padding-left: 40px
}

.p-48 {
	padding: 48px
}

.pt-48 {
	padding-top: 48px
}

.pr-48 {
	padding-right: 48px
}

.pb-48 {
	padding-bottom: 48px
}

.pl-48 {
	padding-left: 48px
}

.p-56 {
	padding: 56px
}

.pt-56 {
	padding-top: 56px
}

.pr-56 {
	padding-right: 56px
}

.pb-56 {
	padding-bottom: 56px
}

.pl-56 {
	padding-left: 56px
}

.p-64 {
	padding: 64px
}

.pt-64 {
	padding-top: 64px
}

.pr-64 {
	padding-right: 64px
}

.pb-64 {
	padding-bottom: 64px
}

.pl-64 {
	padding-left: 64px
}

.sr .has-animations .is-revealing {
	visibility: hidden
}

.button {
	display: inline-flex;
	font-size: 13px;
	letter-spacing: 0px;
	font-weight: 700;
	line-height: 16px;
	text-transform: uppercase;
	text-decoration: none !important;
	background-color: #fff;
	color: #00A2B8 !important;
	border: none;
	border-radius: 2px;
	cursor: pointer;
	justify-content: center;
	padding: 16px 32px;
	height: 48px;
	text-align: center;
	white-space: nowrap
}

.button:active {
	outline: 0
}

.button::before {
	border-radius: 2px
}

.button-shadow {
	position: relative
}

.button-shadow::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-shadow: 0 8px 24px rgba(32, 43, 54, 0.12);
	mix-blend-mode: multiply;
	transition: box-shadow .15s ease
}

.button-shadow:hover::before {
	box-shadow: 0 8px 24px rgba(32, 43, 54, 0.25)
}

.button-sm {
	padding: 8px 24px;
	height: 32px
}

.button-sm.button-shadow::before {
	box-shadow: 0 4px 16px rgba(32, 43, 54, 0.12)
}

.button-sm.button-shadow:hover::before {
	box-shadow: 0 4px 16px rgba(32, 43, 54, 0.25)
}

.button-primary,
.button-secondary {
	color: #fff !important;
	transition: background .15s ease
}

.button-primary {
	background: #00b9d2;
	background: linear-gradient(65deg, #00A2B8 -25%, #00CFEB 100%)
}

.button-primary:hover {
	background: #00bbd5;
	background: linear-gradient(65deg, #00a4bb -25%, #00d1ee 100%)
}

.button-secondary {
	background: #00b5e7;

}

.button-secondary:hover {
	background: #00b5e7;
}

.button-block {
	display: flex
}

.site-header {
	position: relative;
	padding: 24px 0
}

.site-header::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;
	background: #00b5e7;
	background: linear-gradient(57deg, #00b5e7 0%, #1E4D92 100%);
	-webkit-transform-origin: 0;
	transform-origin: 0;
	-webkit-transform: skewY(-12deg);
	transform: skewY(-12deg)
}

.site-header2::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;
	background: #00b5e7;
	background: linear-gradient(57deg, #00b5e7 0%, #1E4D92 100%);
	-webkit-transform-origin: 0;
	transform-origin: 0;
}


.site-header-inner {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center
}

.site-header {
	position: relative;
	padding: 24px 0
}

.site-header-imprint::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;
	background: #00b5e7;
	background: linear-gradient(0deg, #00b5e7 0%, #1E4D92 100%);
	-webkit-transform-origin: 0;
	transform-origin: 0;
}


.site-header-inner {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center
}

.header-links {
	display: inline-flex
}

.header-links li {
	display: inline-flex
}

.header-links a:not(.button) {
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.8);
	text-transform: uppercase;
	text-decoration: none;
	line-height: 16px;
	padding: 8px 24px
}

.header-links a:not(.button):hover,
.header-links a:not(.button):active {
	color: #fff
}


.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 90%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  margin-left: 6vh;
  margin-right: 5vh;
}

.active, .accordion:hover {
  background-color: #ccc;
}

.panel {
  padding: 0 18px;
  width: 90%;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

@media (min-width: 641px) {
	.site-header::before {
		height: 640px
	}
}

.hero {
	position: relative;
	padding-top: 40px;
	overflow: hidden
}

.titleinbox {
	color: #00b5e7 !important;
	margin-left: 1em;
	padding-top: 1em;
} 

.subtitleinbox {
	color: #1d1c1c !important;
	margin-left: 1em;
	padding-top: 0em;
	font-weight: 300;
    line-height: 1.5;
} 

.questioninbox {
	color: #00b5e7 !important;
	margin-left: 1.8em;
	padding-top: 0;
	font-weight: 300;
    line-height: 1.5;
    text-align: left;
} 

.answerinbox {
	color: #4a4a4a !important;
	margin-left: 2.2em;
	margin-right: 1.8em;
	font-size: 16px;
	font-weight: 100;
    line-height: 1.5;
    text-align: left;
} 

.hero-paragraph {
	margin-bottom: 32px
}

.hero-media {
	position: relative;
	height: 200px;
	margin-top: 40px;
    background-image: url("https://i.imgur.com/lImlZV9.png");
    background-size: contain;
    background-repeat: no-repeat;
	border-radius: 4px 4px 0 0
}


.hero-media2 {
	position: relative;
	height: 100%;
	margin-top: 4em;
	background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
	border-radius: 4px 4px 0 0
}

.hero-media2::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-shadow: 0 16px 48px #E3E7EB;
	mix-blend-mode: multiply
}

.hero-media::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-shadow: 0 16px 48px #E3E7EB;
	mix-blend-mode: multiply
}




@media (min-width: 641px) {
	.hero {
		padding-top: 80px
	}
	.hero-paragraph {
		margin-bottom: 40px;
		padding-left: 72px;
		padding-right: 72px
	}
	.hero-media {
		margin-top: 64px;
		height: 400px
	}
}

.clients .section-inner {
	padding-top: 30px;
	padding-bottom: 30px
}

.clients ul {
	display: flex;
	flex-wrap: wrap
}

.clients li {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 10px 0
}

@media (min-width: 641px) {
	.clients .section-inner {
		padding-top: 26px;
		padding-bottom: 26px
	}
	.clients ul {
		justify-content: space-around
	}
	.clients li {
		width: auto
	}
}

.features .section-title {
	margin-bottom: 48px
}

.features-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-right: -12px;
	margin-left: -12px
}

.features-wrap:first-child {
	margin-top: -12px
}

.features-wrap:last-child {
	margin-bottom: -12px
}

.feature {
	padding: 12px;
	width: 276px;
	max-width: 276px;
	flex-grow: 1
}

.feature-inner {
	position: relative;
	height: 100%;
	background: #fff;
	padding: 40px 24px
}

.shadow {
	box-shadow: 0 16px 48px #E3E7EB;
}

.feature-inner::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-shadow: 0 16px 48px #E3E7EB;
	mix-blend-mode: multiply
}

@supports (-ms-ime-align: auto) {
	.feature-inner::before {
		box-shadow: 0 16px 48px rgba(32, 43, 54, 0.12)
	}
}

.feature-icon {
	display: flex;
	justify-content: center
}

@media (min-width: 641px) {
	.features .section-title {
		margin-bottom: 56px
	}
	.features .section-inner {
		padding-top: 56px;
		padding-bottom: 64px
	}
}

.feature-extended {
	padding-top: 48px
}

.feature-extended-image,
.feature-extended-body {
	width: 100%
}

.feature-extended-image {
	margin-bottom: 24px
}

.feature-extended-image img,
.feature-extended-image svg {
	width: 100%;
	max-width: 320px;
	height: auto;
	margin: 0 auto;
	overflow: visible
}

.feature-extended-body {
	text-align: center
}

@media (min-width: 641px) {
	.features-extended .section-paragraph {
		padding-left: 72px;
		padding-right: 72px;
		margin-bottom: 0
	}
	.feature-extended {
		display: flex;
		align-items: center;
		padding-top: 72px
	}
	.feature-extended:nth-child(even) .feature-extended-image {
		order: 1
	}
	.feature-extended-image,
	.feature-extended-body {
		width: auto;
		padding: 0 24px
	}
	.feature-extended-image {
		margin-bottom: 0
	}
	.feature-extended-image img,
	.feature-extended-image svg {
		max-width: none;
		width: 320px
	}
	.feature-extended-body {
		text-align: left
	}
}

@media (min-width: 1025px) {
	.feature-extended {
		padding-left: 40px;
		padding-right: 40px
	}
	.feature-extended-image,
	.feature-extended-body {
		padding: 0 40px
	}
	.feature-extended-image img,
	.feature-extended-image svg {
		width: 480px
	}
}

.pricing {
	position: relative;
	overflow: hidden
}

.pricing::before {
	content: '';
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 640px;
	background: #F6F8FA;
	-webkit-transform-origin: 0;
	transform-origin: 0;
	-webkit-transform: skewY(-40deg);
	transform: skewY(-40deg);
	overflow: hidden
}

.pricing .section-title {
	margin-bottom: 48px
}

.pricing-tables-wrap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-right: -12px;
	margin-left: -12px
}

.pricing-tables-wrap:first-child {
	margin-top: -12px
}

.pricing-tables-wrap:last-child {
	margin-bottom: -12px
}

.pricing-table {
	padding: 12px;
	width: 344px;
	max-width: 344px;
	flex-grow: 1
}

.pricing-table-inner {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	background: #fff;
	padding: 24px;
	height: 100%
}

.pricing-table-inner>* {
	position: relative;
	width: 100%
}

.pricing-table-inner::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	box-shadow: 0 16px 48px #E3E7EB;
	mix-blend-mode: multiply
}

.profileimage {
    border-radius: 50%; 
    width: 500px;
    height: 200px;
}

@supports (-ms-ime-align: auto) {
	.pricing-table-inner::before {
		box-shadow: 0 16px 48px rgba(32, 43, 54, 0.12)
	}
}

.pricing-table-header {
	position: relative
}

.pricing-table-header::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: block;
	height: 1px;
	background: #E3E7EB;
	background: linear-gradient(to right, rgba(227, 231, 235, 0.1) 0, rgba(227, 231, 235, 0.6) 50%, rgba(227, 231, 235, 0.1) 100%)
}

.pricing-table-features {
	color: #92A2B1
}

.pricing-table-features li {
	display: flex;
	align-items: center;
	margin-bottom: 14px
}

.pricing-table-features li .list-icon {
	display: inline-flex;
	width: 16px;
	height: 12px;
	margin-right: 12px
}

.pricing-table-cta {
	align-self: flex-end
}

@media (min-width: 641px) {
	.pricing::before {
		-webkit-transform: skewY(-12deg);
		transform: skewY(-12deg)
	}
	.pricing .section-title {
		margin-bottom: 64px
	}
}

.is-boxed {
	background: #F6F8FA
}

.body-wrap {
	background: #fff;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh
}

.boxed-container {
	max-width: 1440px;
	margin: 0 auto;
	box-shadow: 0 16px 48px #E3E7EB
}

@supports (-ms-ime-align: auto) {
	.boxed-container {
		box-shadow: 0 16px 48px rgba(32, 43, 54, 0.12)
	}
}

main {
	flex: 1 0 auto
}

.section-inner {
	position: relative;
	padding-top: 48px;
	padding-bottom: 48px
}

.section-inner2 {
	    position: relative;
		padding-top: 30vh;
		padding-bottom: 120px
	}

@media (min-width: 641px) {
	.section-inner {
		padding-top: 80px;
		padding-bottom: 80px
	}
    .section-inner2 {
		padding-top: 120px;
		padding-bottom: 120px
	}

}

.site-footer {
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0px;
	color: #92A2B1;
	border-top: 1px solid #E3E7EB
}

.site-footer a {
	color: #92A2B1;
	text-decoration: none
}

.site-footer a:hover,
.site-footer a:active {
	color: #5B6F82;
	text-decoration: underline
}

.site-footer-inner {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	padding-top: 40px;
	padding-bottom: 40px
}

.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
	flex: none;
	width: 100%;
	display: inline-flex;
	justify-content: center
}

.footer-brand,
.footer-links,
.footer-social-links {
	margin-bottom: 24px
}

.footer-links li+li,
.footer-social-links li+li {
	margin-left: 16px
}

.footer-social-links li {
	display: inline-flex
}

.footer-social-links li a {
	padding: 8px
}

@media (min-width: 641px) {
	.site-footer-inner {
		justify-content: space-between
	}
	.footer-brand,
	.footer-links,
	.footer-social-links,
	.footer-copyright {
		flex: 50%
	}
	.footer-brand,
	.footer-copyright {
		justify-content: flex-start
	}
	.footer-links,
	.footer-social-links {
		justify-content: flex-end
	}
	.footer-links {
		order: 1;
		margin-bottom: 0
	}
	.hero {
		position: relative;
		padding-top: 20px;
		overflow: hidden
	}
}
@media (max-width:360px) {
	.hero {
		position: relative;
		padding-top: 20px;
		overflow: hidden
	}
}


.fas{
	font-family: FontAwesome !important;
	font-style: normal;
 }
 .fa{
	font-family: FontAwesome !important;
	font-style: normal;
 }




 .profile {
		top: 300px;
		width: 100%;
 }

 .profile-title {
	 font-size: 2rem;
	 font-weight: bold;
 }
 .profile-img  {
	 width: 50%;
 }
