.show {
    position: absolute !important;
    z-index: 9999 !important;
    cursor: pointer !important;
    top: 7px;
    right: 10px;
}

.blue_bg {
    position: relative;
    background-color: #3ba3d1 !important;
    width: 100%;
    /* min-height: 100% !important; */
    /* height: 100vh!important; */
    display : grid;
    height : auto;
    min-height : 100%;
}

.login_heading_text_container {
    margin-bottom: 40px;
}

.login_container {
    padding: 20px;
    width: 400px !important;
    height: auto !important;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 100px; 
}
.beta {
    font-size: 0.8rem;
    padding: 10px;
}
.login_heading_text {
    font-weight: bold;
    font-size: 1em;
    color: #333;
    margin-bottom: 20px;
}

.login_title {
    display: block;
    font-size: 3em;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
}

.login_subtitle{
    color: #fff;
text-align:center;
}


.login_label {
    font-size: .8em;
    color: #333;
    font-style: italic;
}
.align_right {
    position: relative;
    float: right;
    clear: both;
}

.brand {
    padding: 40px;
}
.login_hint {
    color: #ddd;
    font-size: .8em;
    font-style: italic;
}
.login_hint_333 {
    color: #333;
    font-size: .8em;
    font-style: italic;
}

.gradiant {
    border: none;
    outline: none;
    cursor: pointer;
    background: #1e5799;
    background: -moz-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
    background: -webkit-linear-gradient(left, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
    background: linear-gradient(to right, #1e5799 0%, #01b1e4 0%, #1764a5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1e5799', endColorstr='#1764a5', GradientType=1);
    transition: all ease-in-out;
    text-decoration: none;
    color: #fff;
    text-align: center;
    padding: 2px;
    font-size: 0.7em;
    font-weight: bold;
    text-transform: uppercase;
    max-width: 300px;
    border-radius: 4px;
    padding-left: 10px;
    width: 160px;
    padding-right: 10px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 15px;
}



.slide-in-elliptic-bottom-fwd {
	-webkit-animation: slide-in-elliptic-bottom-fwd 0.7s .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-elliptic-bottom-fwd 0.7s .5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-3-1 11:16:19
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-bottom-fwd
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-elliptic-bottom-fwd {
    0% {
      -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
              transform: translateY(600px) rotateX(30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% -1400px;
              transform-origin: 50% -1400px;
      opacity: 1;
    }
  }
  @keyframes slide-in-elliptic-bottom-fwd {
    0% {
      -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
              transform: translateY(600px) rotateX(30deg) scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) rotateX(0) scale(1);
              transform: translateY(0) rotateX(0) scale(1);
      -webkit-transform-origin: 50% -1400px;
              transform-origin: 50% -1400px;
      opacity: 1;
    }
  }

  .active {
      background-color: #fff;
      color:#202020 ;
  }
  
.color_grey {
    color:#202020;

}

  @media (max-width:360px) {
    .login_container {
        padding: 20px;
        width: 100% !important;
        height: 400px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 4px;
    }
  }

.term {
    text-align: justify;
    font-size: 1em;
    margin-bottom: 10px;
}
.dark {
    color: #333;
    font-size: 1.2em;
    padding:5px;
}
ul {
    padding:0 !important;
    padding-left: 45px !important;
    margin:0 !important;
}