header {
	font-family: "Raleway" !important;
	display: block
}

.site-header {
	font-family: "Raleway";
	position: relative;
	padding: 24px 0;
}

.site-header::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;
	background: #00b5e7;
	background: linear-gradient(57deg, #00b5e7 0%, #1E4D92 100%);
	-webkit-transform-origin: 0;
	transform-origin: 0;
	-webkit-transform: skewY(-12deg);
	transform: skewY(-12deg)
}

.site-header2::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;
	background: #00b5e7;
	background: linear-gradient(57deg, #00b5e7 0%, #1E4D92 100%);
	-webkit-transform-origin: 0;
	transform-origin: 0;
}


.site-header-inner {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center
}

.site-header {
	position: relative;
	padding: 24px 0
}

.site-header-imprint::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500px;
	background: #00b5e7;
	background: linear-gradient(0deg, #00b5e7 0%, #1E4D92 100%);
	-webkit-transform-origin: 0;
	transform-origin: 0;
}


.site-header-inner {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center
}

.header-links {
	display: inline-flex
}

.header-links li {
	display: inline-flex
}

.header-links a:not(.button) {
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0px;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.8);
	text-transform: none;
	text-decoration: none;
	line-height: 16px;
	/* padding: 8px 24px; */
	margin: 8px 24px;
}

.header-links a:not(.button):hover,
.header-links a:not(.button):active {
	color: #fff
}

.m-0 {
	margin: 0
}
.list-reset {
	list-style: none;
	padding: 0
}

  



@media (min-width: 641px) {
	.site-header::before {
		height: 640px
	}
}
