.fas {
    font-family: FontAwesome !important;
    font-style: normal;
}
.custom_tag {
    margin-left: 10px;
    color: #fff;
}
.has-pointer{
    cursor: pointer;
}
.is-taged {
    background-color: #277db1 !important;
    color:#fff !important;
}
.is-not-taged {
    color:#fff !important;
    background-color: #b0b0b0 !important;
}
.heading-text {
    margin-top: 10px;
    margin-bottom: 20px;
}
.knowledge_text{
    color: #606060;
    margin-bottom: 10px;
}
.is-rounded{
    margin-right: 10px !important;
    border-radius: 40px!important;
}