.avatar {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 50px;
    cursor: pointer;
}
.username {
    color: #fff;
    margin-top: 15px;
    margin-right: 15px;
    margin-left: 10px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
}